<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Application Type</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined :error="$v.application_type.title.$error"
                  dense
                  v-model="application_type.title">
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.title">**{{ errors.title[0] }}</span>

              <span class="text-danger" v-if="$v.application_type.title.$error">This Title field is Required</span>
            </v-col>


            <v-col cols="12" sm="12" md="12">
              <label>
                <strong>Description</strong>
              </label>
              <ckeditor :config="editorConfig"
                        v-model="application_type.description">
              </ckeditor>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-switch label="Status" v-model="application_type.is_active"></v-switch>
            </v-col>
            <!--            <v-col cols="12" sm="12" md="4">
                          Status
                          <v-switch
                              v-model="application_type.is_active"
                              :label="application_type.is_active ? 'Active' : 'InActive'"
                          ></v-switch>
                        </v-col>-->

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            v-if="checkIsAccessible('application-type', 'create') || checkIsAccessible('application-type', 'edit')"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import CountryService from "@/services/country/CountryService";

const country = new CountryService();
import {required, requiredIf} from "vuelidate/lib/validators";
import ApplicationTypeService from "@/services/application/type/ApplicationTypeService";

const applicationType = new ApplicationTypeService();
export default {
  validations: {
    application_type: {
      title: {required},

    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      edit: false,
      loading: false,
      errors: [],
      application_type: {
        title: '',
        description: '',
        country_id: '',
        application_type: '',
        /*is_active:true,*/
      },
      types: [
        {value: 'study', text: 'Study'},
        {value: 'visit', text: 'Visit'},
        {value: 'work', text: 'Work'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      countries: [],
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {

      this.dialog = true;
    },
    createApplicationType() {
      this.openDialog();
      this.resetForm();
      this.title = "Create";
      this.edit = false;
    },
    editApplicationType(item) {
      this.openDialog();
      this.edit = true;
      this.application_type = item;
      this.title = "Edit";
    },
    resetForm() {
      this.application_type = {
        title: '',
        description: '',
        country_id: '',
        application_type: '',
        /* is_active:true,*/
      };
      this.errors = [];
    },
    getCountries() {
      country
          .getAllCountry({'is_operating_country': 1})
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      applicationType
          .create(this.application_type)
          .then(response => {
            this.$snotify.success("Application Type created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(this.loading = false)
    },
    update: function () {
      this.loading = true;
      applicationType
          .update(this.application_type.id, this.application_type)
          .then(response => {
            this.$snotify.success("Application Type updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(this.loading = false)

    },
  },
  mounted() {
    this.getCountries();
  }
}
</script>
